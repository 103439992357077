:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary: #eb4960;
  --color-primary-light: #e86363;
  --color-grey-light: #f2f2f2;
  --add-button-color: #726a6b;
  --secondary-background-color: #263238;
  --secondary-green-color: #9ab999;
  --header-text-color: #262626;
}

//! Theme
@background-color: #9ab999;
@title-color: #20292e;
@secondary-background-color: #263238;

// ColorPicker Variables
@white-color: #ffffff;
@black-color: #000000;

//global style variables
@primary-color: #eb4960;
@secondary-color: #fa8c15;
@text-color: #545454;
@text-color-secondary: #595959;
@heading-color: #535353;
@header-text-color: #262626;
@layout-header-background: #fefefe;
@layout-header-background-dark: #003365;
@layout-footer-background: #fffffe;
@body-background: #f5f5f5;
@nav-bar-bg: #fffffc;
@nav-bar-text-color: @text-color;

@nav-dark-bg: #003366;
@nav-dark-text-color: #fff;
@menu-dark-bg: @nav-dark-bg;
@menu-dark-color: @nav-dark-text-color;
@menu-dark-highlight-color: @secondary-color;
@menu-dark-arrow-color: @nav-dark-text-color;
@hor-nav-text-color: #fffffd;

@border-radius-base: 6px;
@border-radius-sm: 4px;
@border-radius-lg: 10px;

@primary-red-color: #eb4960;
@secondary-red-color: #f2727f;

@logo-color: #f4837d;
@brand-color: #53a1fe;
@primary-green-color: #89a386;
@secondary-green-color: #9ab999;
@add-button-color: #726a6b;
@select-color: #15161b;
@step-active-color: #92e3a9;
@purple-color: #800080;
@rebecca-purple: #663399;
@pale-violet-red: #db7093;
@send-color: #feceaa;
@add-member-text: #83adfe;
@delete-project-text: #f9536d;
@view-project-text: #68ffba;
@border-color-card: #e5e5e5;
@card-bg-color: #efefef;
@tab-bg-color: #222222;
@h2-font-size: 20px;

//error variables
@error-border: #cc0033;
@error-background: #f1c1c1;

//Table Height
@table-height-with-data: 452px;
@table-height-with-data-search: 452px;
@table-height-without-data: 452px;
@table-header-color: #72788f;
@table-header-background: #263238;
@table-cell-color: #263238;
@class-prefix: 'gx';

@gx-card-shadow-lg: 0 0 4px 4px fade(@black-color, 08%);

@font-family: 'NoirPro', sans-serif;

@class-prefix: gx;

// Grey ColorPicker Variables
@grey-2: #fafafa;
@grey-3: #f5f5f5;
@grey-4: #e8e8e8;
@grey-5: #d9d9d9;
@grey-6: #bfbfbf;
@grey-7: #8c8c8c;
@grey-8: #595959;
@grey-9: #262626;

@info-color: @blue-6;
@success-color: @green-6;
@processing-color: @primary-color;
@error-color: @red-6;
@highlight-color: @red-6;
@warning-color: @orange-6;
@normal-color: #d9d9d9;
@danger-color: #f44336;
@light-color: #e8eaf6;
@dark-color: @grey-9;

@blue-color: @blue-6;
@indigo-color: #3c1991;
@purple-color: @purple-6;
@pink-color: @pink-6;
@red-color: @red-6;
@orange-color: @orange-6;
@yellow-color: @yellow-6;
@amber-color: @yellow-color;
@green-color: @green-6;
@teal-color: #20c997;
@dark-green: #4dbd74;
@cyan-color: @cyan-6;
@gold-color: @gold-6;
@lime-color: @lime-6;
@sepia-color: #7c3535;
@white-color: #fff;
// Background color for `<body>`
@heading-color-dark: fade(#fff, 100%);
@text-color-dark: fade(#fff, 85%);
@text-color-secondary-dark: fade(#fff, 65%);
@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;
@line-height-base: 1.3;
@border-radius-xxl: 30px;
@border-radius-circle: 50%;

// Border color
@border-color-base: hsv(0, 0, 85%); // base border outline a component
@border-color-split: hsv(0, 0, 91%); // split border inside a component
@border-width-base: 1px; // width of the border for a component
@border-style-base: solid; // style of a components border
@border-color: @border-color-split;

// LINK
@link-color: @primary-color;
// @link-hover-color: color(~`colorPalette('@{link-color}', 5) `);
// @link-active-color: color(~`colorPalette('@{link-color}', 7) `);

@h1-font-size: 22px;
@h2-font-size: 20px;
@h3-font-size: 18px;
@h4-font-size: 16px;
@h5-font-size: 14px;
@h6-font-size: 12px;

@font-size-10: 10px;
@font-size-13: 13px;
@font-size-15: 15px;
@font-size-24: 24px;
@font-size-28: 28px;
@font-size-30: 30px;
@font-size-36: 36px;

@headings-font-weight: 400;
@headings-line-height: 1.2;

@font-weight-light: 300;
@font-weight-normal: 400;
@font-weight-medium: 500;
@font-weight-semi-bold: 600;
@font-weight-bold: 700;
@font-weight-heavy: 900;

// Sidebar Variables
@sidebar-width: 280px;
@sidebar-mini-drawer-width: 80px;
@sidebar-padding-lr: 30px;

@nav-bar-bg-darken: darken(@nav-bar-bg, 3%);
@sidebar-hover-color: @primary-color;

@screen-xs-max: 575px; // Dark Sidebar Variables
@nav-dark-bg-darken: lighten(@nav-dark-bg, 6%);
@sidebar-dark-hover-color: @white-color;

// Menu
// ---
@menu-inline-toplevel-item-height: 42px;
@menu-item-height: 42px;
@menu-collapsed-width: 80px;
// @menu-bg: @component-background;
@menu-item-color: @text-color;
@menu-highlight-color: @primary-color;
// @menu-item-active-bg: @item-active-bg;
@menu-item-group-title-color: #545454;
@menu-highlight-dot-color: @pink-5;

// dark theme
@menu-dark-submenu-bg: @nav-dark-bg-darken;
@menu-dark-item-selected-bg: @nav-dark-bg-darken;
@menu-dark-item-active-bg: @white-color;

// Layout
@layout-body-background: @body-background;
@layout-header-height: 72px;
@layout-header-height-res: 50px;
@layout-header-padding: 0 32px;
@layout-header-padding-res: 0 15px;
@layout-footer-padding: 14px 32px;
@layout-footer-padding-res: 14px 15px;
@layout-sider-background: @nav-dark-bg;
@layout-trigger-height: 30px;
@layout-trigger-background: #002140;
@layout-trigger-color: @white-color;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 28px;
@layout-main-content-padding: 32px;
@layout-main-content-padding-res: 15px;

// Card
// ---
@card-head-color: @heading-color;
// @card-head-background: @component-background;
@card-head-padding: 16px;
@card-inner-head-padding: 12px;
@card-padding-base: 24px;
@card-padding-wider: 24px;
// @card-actions-background: @background-color-light;
@card-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);

@gx-card-padding-base: 24px;
@gx-card-padding-sm: @gx-card-padding-base - 10px;
@gx-card-shadow: 0 0 5px 5px fade(@black-color, 03%);
@gx-card-shadow-lg: 0 0 4px 4px fade(@black-color, 08%);
@gx-card-shadow-md: 0 0 5px 5px fade(@black-color, 05%);
@gx-card-margin-base: 32px;
@gx-card-margin-base-res: 20px;

// Gutter
@grid-gutter-width: 32px;

//Sizes Variables
@size-8: 8px;
@size-10: 10px;
@size-20: 20px;
@size-30: 30px;
@size-36: 36px;
@size-40: 40px;
@size-50: 50px;
@size-60: 60px;
@size-80: 80px;
@size-100: 100px;
@size-120: 120px;
@size-150: 150px;
@size-175: 175px;
@size-200: 200px;

//latter Spacing variables
@letter-spacing-base: 3px;
@letter-spacing-lg: 6px;
@letter-spacing-xl: 8px;

//CRM Image Location
@image_path: '../../assets/images';

// Apps Variables
@app-sidebar-width: 230px;
@app-chat-sidebar-width: 315px;

// Form
// ---
@label-required-color: @highlight-color;
@label-color: @heading-color;
@form-item-margin-bottom: 20px;
@form-item-trailing-colon: true;
@form-vertical-label-padding: 0 0 8px;
@form-vertical-label-margin: 0;

// Input
// ---
@input-height-base: 36px;
@input-height-lg: 40px;
@input-height-sm: 24px;
@input-height-base-sm: 28px;

// Buttons
@btn-height-base: 36px;
@btn-height-lg: 40px;
@btn-height-sm: 24px;
@btn-height-base-sm: 28px;
@btn-font-size-lg: @font-size-lg;
@btn-font-size-sm: @font-size-sm;
@btn-font-size-xs: @font-size-sm - 2px;
@btn-padding-xs: 0 6px;
@btn-border-radius-base: @border-radius-sm;
@btn-border-radius-sm: @border-radius-sm;
@btn-padding-base: 0 15px;
@btn-padding-lg: @btn-padding-base;
@btn-padding-sm: 0 7px;

// Dragndrop
@dragndrop-paddding-tb: 24px;
@dragndrop-paddding-lr: 16px;

//Pricing Table
@gx-pricing-table-base: 30px;

// Customizer
@gx-customizer-width: 370px;
@gx-customizer-base: 20px;

//Framed Layout
@framed-layout-base: 20px;
