@import '../../../styles/variables.less';

.logo {
  width: 152px;
  height: 61px;
  left: 22px;
  top: 11px;
  font-family: 'poppins';
  font-style: normal;
  font-weight: 700;
  font-size: @font-size-36;
  line-height: 170.23%;
  display: flex;
  align-items: center;
  color: @logo-color;
}

.env-dropdown {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-width: 250px;
    background-color: @primary-red-color;
    color: @white-color;
    border-radius: 0px 0px 10px 10px;
    border: 0;
  }

  .ant-select-dropdown {
    background-color: @primary-red-color;
    padding: 8px;
    border-radius: 6px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);

    .create-new-env {
      color: @white-color;
      font-size: @h6-font-size;
      cursor: pointer;
      padding: 5px;
    }
  }

  .ant-select-selection-placeholder {
    color: @red-color;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: @black-color;
  }

  .env-select:hover {
    border-color: @red-color !important;
  }

  .ant-select-item {
    font-size: @h6-font-size;
    color: @select-color;
    border-radius: 10px;
    margin-bottom: 5px;
    padding: 6px;
  }

  .ant-select-arrow {
    color: @white-color;
  }

  .ant-select-item-option-content {
    color: @white-color;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: @white-color;
  }
}
