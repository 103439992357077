:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary: #eb4960;
  --color-primary-light: #e86363;
  --color-grey-light: #f2f2f2;
  --add-button-color: #726a6b;
  --secondary-background-color: #263238;
  --secondary-green-color: #9ab999;
  --header-text-color: #262626;
}
#root {
  height: 100vh;
}
::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #f2727f;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #f2727f;
  /* creates padding around scroll thumb */
}
.ant-btn-icon {
  font-size: 17px;
}
.header-notification {
  display: flex;
  align-items: center;
}
.header-notification > * {
  margin-right: 20px;
}
.header-notification > .notification-bell {
  font-size: 20px;
  cursor: pointer;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item {
  margin: 12px 0;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item > .menu-icons {
  font-size: 20px;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item > span {
  font-size: 18px;
}
.ant-table .ant-table-tbody .anticon {
  font-size: 20px;
  padding: 0 4px;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding: 0;
  height: auto;
}
.event-header-image .ant-upload-select-picture-card {
  width: 100%;
}
.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.event-form .ant-form-item-label {
  padding: 0;
  margin: 0;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.ant-message-custom-content > .ant-message-custom-content .ant-message-info {
  height: 36px;
  display: flex;
  align-items: center;
}
.icon-btn {
  display: flex;
  align-items: center;
}
.upload-image-container .ant-upload-select-picture-card {
  width: 100%;
}
.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.custom-icons-list {
  margin-right: 10px;
}
.ant-table-column-sorters {
  padding: 0;
}
.login-content {
  padding: 35px;
  width: 100%;
}
.ant-input {
  border-radius: 10px;
}
.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-center {
  text-align: center;
}
.ant-btn-sm {
  line-height: 0;
}
.attendee-details .ant-form-item {
  min-width: 215px;
  margin-right: 16px;
}
.rsvp-modal .ant-modal-footer div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-modal-body {
  padding: 24px !important;
}
.ant-input-suffix {
  display: flex;
  align-items: center;
}
.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.option-input:focus {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
  box-shadow: unset;
}
.option-input:hover {
  border-color: transparent;
  border-right-width: 0;
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}
.donation-list {
  overflow: auto;
}
.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}
.donation-card.active {
  background-color: #efefef;
}
.ministry-modal {
  height: 500px;
}
.ministry-modal .ant-modal-body {
  padding: 0;
}
.ministry-modal object {
  min-height: 500px;
}
.dialog .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialog .ant-modal-footer > button:first-child {
  margin-right: 0px;
}
.action-icons {
  display: flex;
  align-items: center;
}
.action-icons button:first-child {
  margin-right: 12px;
}
.action-icons > button {
  margin-bottom: 0;
}
.m-30 {
  margin: 30px;
}
.m-0 {
  margin: 0;
}
.mt-10 {
  margin-top: 10px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-30 {
  margin-right: 30px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-30 {
  margin-left: 30px;
}
.p-0 {
  padding: 0;
}
.d-flex {
  display: flex;
}
.flex-horizontal {
  flex-direction: row;
}
.flex-vertical {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-shrink-1 {
  flex-shrink: 1;
}
.flex-shrink-2 {
  flex-shrink: 2;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-center {
  align-self: center;
}
.gap-16 {
  gap: 16px;
}
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-48 {
  width: 48%;
}
.line-24 {
  line-height: 24px;
}
.fill-width {
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.pointer-none {
  pointer-events: none;
}
.b-0 {
  border: none;
}
.font-xx-large {
  font-size: xx-large;
}
.font-large {
  font-size: large;
}
.font-18 {
  font-size: 18px;
}
.gx-layout-content {
  background-color: #9ab999;
}
.gx-main-content-wrapper .title {
  font-weight: 400;
  font-size: 30px;
}
.font-poppins {
  font-family: 'Poppins', sans-serif;
}
.ant-btn .ant-btn-icon {
  margin-right: 8px;
}
.ant-btn {
  line-height: 1.3;
}
.search-input {
  width: 100%;
  caret-color: #fff;
  color: #89a386;
  border: 1px solid #89a386 !important;
  border-radius: 20px;
}
.search-input .ant-input::placeholder {
  color: #89a386;
}
.search-input .ant-input {
  color: #89a386 !important;
}
.search-input.ant-input-affix-wrapper > input.ant-input {
  padding-left: 2px;
}
.search-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #89a386;
  box-shadow: 4px 4px 30px rgba(137, 163, 134, 0.25);
  border-radius: 20px;
}
.search-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
  border: 1px solid #89a386;
  box-shadow: 4px 4px 30px rgba(137, 163, 134, 0.25);
  border-radius: 20px;
}
.chart {
  width: 95%;
  height: 440px;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.full-height-card {
  height: calc(100% - 82px);
  border: none;
  background-color: #9ab999;
}
.full-height-card .sub-header .title {
  color: #000000;
  font-size: 36px;
}
.full-height-card .sub-header .message {
  color: #fff;
  font-size: px;
}
.full-height-card .sub-header .add-btn {
  margin-top: 10px;
}
.add-container {
  padding: 50px 50px 50px 50px;
  background: #263238;
  border-radius: 25px;
}
.ant-tabs-nav-more svg {
  width: 24px;
}
.tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.tabs .ant-tabs-nav-list .ant-tabs-tab {
  padding: 12px 0 0;
  transition: none;
}
.ant-tabs-ink-bar {
  display: none;
}
.ant-tabs.ant-tabs-top.tabs .ant-tabs-nav {
  margin: 0px;
}
.ant-tabs.ant-tabs-top.tabs .ant-tabs-nav::before {
  border-bottom: none;
  content: none;
}
.ant-tabs-content {
  height: 100%;
}
.tab-content-block {
  min-height: 250px;
  height: calc(100vh - 200px);
  overflow: auto;
  background: #263238;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 33px;
  color: #fff;
  transition: none;
}
.ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane:first-child .tab-content-block {
  border-radius: 0 30px 30px 30px;
}
.ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .tab-content-block {
  border-radius: 30px;
}
.no-border-top-left-radius .tab-content-block {
  border-radius: 0 30px 30px 30px;
}
.without-sub-header-content .ant-tabs-content-holder {
  height: calc(100vh - 150px);
  background: #263238;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 33px;
  color: #fff;
}
.ant-tabs .ant-tabs-tab {
  font-size: 24px;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background: unset;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border: none;
  background: unset;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 32px;
}
.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  float: left;
  padding: 10px 35px;
  text-decoration: none;
  font-size: 22px;
  color: #000000;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}
.ant-tabs-tab.ant-tabs-tab-active {
  z-index: 3;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background: #263238;
  color: #fff;
}
.ant-tabs-tab.ant-tabs-tab ::before,
.ant-tabs-tab.ant-tabs-tab ::after,
.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::before,
.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::after {
  /* All pseudo elements are
     abs. positioned and on bottom */
  position: absolute;
  bottom: 0;
}
.ant-tabs-tab.ant-tabs-tab:last-child::after,
.ant-tabs-tab.ant-tabs-tab:last-child .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::after,
.ant-tabs-tab.ant-tabs-tab:first-child::before,
.ant-tabs-tab.ant-tabs-tab:first-child .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::before,
.ant-tabs-tab.ant-tabs-tab-active::after,
.ant-tabs-tab.ant-tabs-tab-active::before,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::after,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::before {
  content: '';
}
.ant-tabs-tab.ant-tabs-tab-active::before,
.ant-tabs-tab.ant-tabs-tab-active::after {
  background: #fff;
  /* Squares below circles */
  z-index: 1;
}
.ant-tabs-tab.ant-tabs-tab::before,
.ant-tabs-tab.ant-tabs-tab::after {
  background: #263238;
  width: 20px;
  height: 20px;
  bottom: 0px;
  position: absolute;
}
.ant-tabs-tab.ant-tabs-tab::before {
  left: -20px;
}
.ant-tabs-tab.ant-tabs-tab::after {
  right: -20px;
}
.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::after,
.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::before {
  width: 50px;
  height: 50px;
  border-radius: 20px;
  background: #222222;
  z-index: 2;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::after,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::before {
  background-color: #9ab999;
}
/* First and last tabs have different
   outside color needs */
.ant-tabs-tab.ant-tabs-tab-active:first-child .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::before,
.ant-tabs-tab.ant-tabs-tab-active:last-child .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::after {
  background: #222222;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::before {
  left: -50px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::after {
  right: -50px;
}
.ant-tabs-tab .ant-tabs-tab-btn {
  transition: none;
}
.no-border-top-left-radius .ant-tabs-content-holder {
  border-top-left-radius: 0px;
}
.no-border-top-right-radius .ant-tabs-content-holder {
  border-top-right-radius: 0px;
}
.card-body-p-24 .ant-card-body:first-child {
  padding: 12px 24px;
}
.primary-button {
  background-color: #eb4960;
  color: #fff;
  border-color: #eb4960;
  display: inline-block;
  font-weight: 400;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 35px;
}
.primary-button.ant-btn-primary:hover {
  background-color: #eb4960;
  color: #fff;
  border-color: #eb4960;
}
.primary-button.ant-btn-primary:focus {
  background-color: #eb4960;
  color: #fff;
  border-color: #eb4960;
}
.primary-button .ant-btn:not([disabled]):active {
  background-color: #eb4960;
  color: #fff;
  border-color: #eb4960;
}
.primary-button .ant-btn-primary:active {
  background-color: #eb4960;
  color: #fff;
  border-color: #eb4960;
}
.primary-button.ant-btn-primary[disabled],
.primary-button .ant-btn-primary[disabled]:hover,
.primary-button .ant-btn-primary[disabled]:focus,
.primary-button .ant-btn-primary[disabled]:active {
  background-color: #726a6b;
  color: #fff;
  border-color: #726a6b;
}
.secondary-button {
  background-color: #9ab999;
  color: #fff;
  border-color: #9ab999;
  display: inline-block;
  font-weight: 400;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 35px;
}
.secondary-button.ant-btn-primary:hover {
  background-color: #9ab999;
  color: #fff;
  border-color: #9ab999;
}
.secondary-button.ant-btn-primary:focus {
  background-color: #9ab999;
  color: #fff;
  border-color: #9ab999;
}
.secondary-button .ant-btn:not([disabled]):active {
  background-color: #9ab999;
  color: #fff;
  border-color: #9ab999;
}
.secondary-button .ant-btn-primary:active {
  background-color: #9ab999;
  color: #fff;
  border-color: #9ab999;
}
.secondary-button.ant-btn-primary[disabled],
.secondary-button .ant-btn-primary[disabled]:hover,
.secondary-button .ant-btn-primary[disabled]:focus,
.secondary-button .ant-btn-primary[disabled]:active {
  background-color: #726a6b;
  color: #fff;
  border-color: #726a6b;
}
.sub-header-button {
  background-color: #726a6b;
  color: #fff;
  border-color: #726a6b;
  display: inline-block;
  font-weight: 400;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 35px;
}
.sub-header-button.ant-btn-primary:hover {
  background-color: #726a6b;
  color: #fff;
  border-color: #726a6b;
}
.sub-header-button.ant-btn-primary:focus {
  background-color: #726a6b;
  color: #fff;
  border-color: #726a6b;
}
.sub-header-button .ant-btn:not([disabled]):active {
  background-color: #726a6b;
  color: #fff;
  border-color: #726a6b;
}
.sub-header-button .ant-btn-primary:active {
  background-color: #726a6b;
  color: #fff;
  border-color: #726a6b;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.white-color {
  color: #fff;
}
.fw-600 {
  font-weight: 600;
}
.secondary-btn-box-shadow {
  box-shadow: 5px 5px 20px rgba(235, 73, 96, 0.6);
  color: #000000;
}
.secondary-btn-box-shadow.ant-btn-primary:hover {
  background-color: #9ab999;
  color: #000000;
  border-color: #9ab999;
}
.secondary-btn-box-shadow.ant-btn-primary:focus {
  background-color: #9ab999;
  color: #000000;
  border-color: #9ab999;
}
.ant-form-item {
  margin-top: 10px;
}
.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  border-color: unset;
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  background-color: #f1c1c1;
  border: 1px solid #cc0033;
  outline: none;
}
.ant-form-item-explain.ant-form-item-explain-error {
  color: #cc0033;
}
.ant-form-item-label > label {
  color: #fff;
}
.tabs-no-data {
  margin-top: 5%;
}
.ant-form-item .ant-select {
  width: unset;
}
.steps-content {
  display: flex;
  justify-content: center;
}
.common-steps .ant-steps-item-icon {
  display: none;
}
.common-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: unset;
}
.common-steps .ant-steps-item-active {
  color: #92e3a9;
}
.common-steps .ant-steps-item-title {
  font-weight: 600;
}
.common-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #fff !important;
  margin-left: 10px;
}
.common-steps .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-content .ant-steps-item-description {
  color: #fff;
  font-weight: 500;
}
.common-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #fff;
  font-weight: 500;
}
.common-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #fff;
  font-weight: 500;
}
.common-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #feceaa;
}
.common-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #feceaa;
}
.common-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #feceaa;
}
.common-steps .ant-steps-item-title::after {
  top: 11px;
  height: 3px;
  background: #feceaa;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.hide {
  display: none;
}
.loading_spinner {
  margin-left: 50%;
  margin-top: 10%;
}
.scroll_spinner {
  margin-left: 50%;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  display: inline-block;
  color: #fff;
}
.max-width-column {
  max-width: 400px;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  ) .ant-select-selector {
  border-color: unset;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: unset;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #545454;
}
.ant-table-tbody > tr .ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: unset;
}
.ant-popover-inner {
  max-width: 270px;
  background-color: #262626 !important;
  border-radius: 15px;
}
.ant-popover .ant-popover-arrow:before {
  background: #262626;
}
.ant-popover-inner-content {
  background: #262626;
  border-radius: 15px;
}
.ant-popconfirm-title {
  color: white !important;
}
.ant-popover-message-title {
  max-width: 250px;
  color: #fff !important;
  overflow-wrap: break-word;
}
.ant-popover-arrow-content::before {
  background: linear-gradient(to left, #262626 50%, #262626 50%) no-repeat -10px -10px;
}
.ant-popover-arrow-content {
  background-color: #262626;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 20px;
}
.no-data svg {
  width: 250px;
  height: auto;
  margin-bottom: 20px;
}
.no-data .title {
  font-size: 30px;
}
.add-title {
  margin-bottom: 0;
}
.ant-picker {
  padding: 6px 11px;
  color: #545454;
}
.ant-modal-close {
  top: 26px;
  right: 26px;
}
.ant-form-item-required::after {
  visibility: visible;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: #fff;
}
.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #eb4960;
}
#editor-1 iframe {
  min-width: 100% !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #fff;
}
.ant-pagination-item {
  overflow: hidden;
}
.ant-pagination-item-active a {
  color: #fff;
  background: #eb4960;
}
.anticon-edit svg {
  fill: #9ab999;
}
@media (min-width: 1370px) {
  .ant-layout-sider:not(.ant-layout-sider-collapsed) {
    min-width: 280px !important;
  }
}
.sb-stepper.ant-steps .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #fff;
}
.sb-stepper.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #9ab999;
  border-color: #9ab999;
}
.sb-stepper.ant-steps .ant-steps-item-finish .ant-steps-item-icon .anticon svg {
  color: #fff;
}
.ant-input[disabled],
.ant-input-disabled {
  background-color: #fff !important;
}
.ant-pagination-disabled .ant-pagination-item-link {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
  cursor: disable;
}
.ant-select-selection-overflow {
  overflow: hidden !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background: #726a6b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
  border: 0;
}
.logout-avitar {
  align-items: center;
  justify-content: center;
  background: #263238;
  color: #eb4960;
}
.logout-avitar .anticon svg {
  margin-top: 16px;
}
.ant-table .ant-table-tbody > tr > td:first-child {
  padding-left: 15px;
}
.add-projects .add-container {
  height: 100%;
}
.ant-layout-sider-children {
  min-height: calc(100vh - 75px);
  overflow: auto;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: normal;
}
.ant-layout-sider-children .ant-menu-submenu-expand-icon,
.ant-layout-sider-children .ant-menu-submenu-arrow {
  right: 21px;
  width: 24px;
}
.ant-layout-sider-children .ant-menu-submenu-open .ant-menu-submenu-title {
  padding-left: 12px !important;
}
.ant-layout-sider-children .gx-icon-btn {
  padding: 12px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #000000;
}
@media (min-width: 1370px) {
  .ant-layout-sider-children .heading .icon.pointer button {
    margin-left: 50px;
  }
}
.ant-layout-sider-children .gx-sidebar-content {
  border-right: none;
  height: calc(100vh - 325px);
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 600;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu.ant-menu-dark {
  background: transparent;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-title-content {
  font-weight: 500;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark .ant-menu-item:hover > span > a {
  color: #9ab999;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-layout-sider-children .gx-sidebar-content .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: #fff;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-item {
  padding-left: 30px !important;
}
.ant-layout-sider-children .gx-sidebar-content ul {
  border-radius: 20px !important;
}
@media (max-width: 1370px) {
  .ant-layout-sider-children .gx-sidebar-content ul {
    padding-left: 0;
  }
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-submenu-title .ant-menu-item-icon {
  order: 1;
  margin-right: 20px;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark.ant-menu-submenu-open .ant-menu-submenu-selected .ant-menu-submenu-title {
  background: #263238;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-sub.ant-menu-inline {
  background: #263238;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark .ant-menu-submenu-title {
  color: #fff;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark .ant-menu-submenu-open {
  background: #263238;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-title,
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-title:hover {
  color: #f2727f;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark .ant-menu-item-selected > span > a {
  color: #9ab999;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark .ant-menu-item:hover {
  color: #9ab999;
}
.ant-layout-sider-children .gx-sidebar-content .ant-divider-horizontal {
  margin: unset;
}
.ant-layout-sider-children .gx-sidebar-content .ant-divider {
  background-color: #000000;
}
.ant-layout-sider-children .gx-sidebar-content .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 20px;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-submenu .ant-menu-submenu-popup .ant-menu .ant-menu-dark .ant-menu-submenu-placement-rightTop {
  background: #9ab999;
}
.ant-layout-sider-children .gx-sidebar-content .menu-theme {
  background-color: #263238;
  border-radius: 20px;
  max-height: 282px;
  transition: all 0.5s ease-out;
}
.ant-layout-sider-children .gx-sidebar-content .menu-theme ul,
.ant-layout-sider-children .gx-sidebar-content .menu-theme li {
  list-style: none;
}
.ant-layout-sider-children .gx-sidebar-content .no-menu-theme {
  background-color: transparent;
  max-height: 54px;
  transition: all 0.5s ease-out;
}
.ant-layout-sider-children .gx-sidebar-content .no-menu-theme .sb-project-avatar {
  background-color: #263238;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  display: block;
}
.ant-layout-sider-children .gx-sidebar-content .ant-menu-dark .ant-menu-item {
  color: #fff;
}
.ant-layout-sider-children .sb-open-menu {
  overflow: hidden;
  max-height: 230px;
  transition: all 0.5s ease-out;
  border-top: 1px solid #000000;
}
.ant-layout-sider-children .sb-open-menu li:hover svg path {
  fill: #9ab999;
}
.ant-layout-sider-children .sb-open-menu .active svg {
  fill: #9ab999;
}
.ant-layout-sider-children .sb-open-menu .active svg path {
  fill: #9ab999;
}
.ant-layout-sider-children .sb-close-menu {
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease-out;
}
.ant-layout-sider-children .sb-project-avatar {
  background-color: #9ab999;
}
.ant-layout-sider-children .sb-project-avatar-text {
  color: #eb4960;
}
.ant-layout-sider-children .ant-divider {
  background-color: #000000;
}
.ant-layout-sider-children .login-icon {
  margin-bottom: 15px;
  width: 100%;
}
.ant-layout-sider-children .login-icon .ant-avatar-lg {
  width: 40px;
}
.ant-layout-sider-children .login-icon .ant-menu.ant-menu-dark {
  background: transparent;
  margin-bottom: 16px;
}
.ant-layout-sider-children .login-icon .ant-menu-submenu-arrow {
  display: none;
}
.ant-layout-sider-children .login-icon .ant-avatar.ant-avatar-lg {
  background-color: #263238;
}
.ant-menu-dark .ant-menu-item.logout-link:hover:hover {
  color: #9ab999 !important;
}
.heading {
  padding-top: 0;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected {
  color: #9ab999;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon path {
  fill: #9ab999;
}
.ant-menu-item-selected {
  background-color: unset !important;
}
.ant-layout-sider-children .gx-sidebar-content .menu-theme {
  margin: 5px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  display: flex;
  justify-content: center;
}
.ant-layout-sider-collapsed {
  width: 80px !important;
}
.main-logo {
  color: #263238;
  transition: ease 0.4s;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.main-logo.close {
  width: 100%;
  overflow: hidden;
}
.add-button {
  height: 32px;
  width: 36px;
}
.add-button .anticon {
  margin-top: 3px;
}
.add-button .ant-btn-icon {
  margin-left: 0;
  margin-right: 0;
}
.avatar-footer .ant-avatar {
  flex-shrink: 0;
}
.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
  border-radius: 0;
  background-color: #263238;
}
.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub .ant-menu-item {
  color: #fff;
}
.ant-layout-sider .login-menu .user-login-email .ant-menu-title-content {
  display: block;
  text-overflow: ellipsis;
}
.ant-layout-sider .login-menu .user-login-email .ant-menu-submenu-title {
  font-size: 16px;
  height: 41px;
  color: #000000;
  padding: 0 14px !important;
}
.ant-layout-sider .login-menu .user-login-email .ant-menu-submenu-title .login-name {
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  flex-shrink: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  padding-right: 30px;
}
.ant-layout-sider .login-menu .user-login-email .ant-menu-submenu-title .ant-menu-submenu-expand-icon {
  height: 24px;
  width: 24px;
  fill: #fff;
}
.ant-layout-sider .login-menu .user-login-email .ant-menu-submenu-title:hover {
  color: #9ab999;
}
.ant-layout-sider .login-menu .user-login-email.ant-menu-submenu-active .ant-menu-submenu-title,
.ant-layout-sider .login-menu .user-login-email.ant-menu-submenu-open .ant-menu-submenu-title {
  color: #9ab999;
}
.ant-menu-submenu-open .ant-menu-submenu-expand-icon {
  rotate: 180deg;
  margin-top: -25px;
  fill: #f2727f;
  transition: transform 0.9s ease;
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 0px;
}
.ant-menu.ant-menu-sub .sub-menu-divider {
  width: 100%;
  height: auto;
  padding: 0;
}
.logo {
  width: 152px;
  height: 61px;
  left: 22px;
  top: 11px;
  font-family: 'poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 170.23%;
  display: flex;
  align-items: center;
  color: #f4837d;
}
.env-dropdown .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-width: 250px;
  background-color: #eb4960;
  color: #fff;
  border-radius: 0px 0px 10px 10px;
  border: 0;
}
.env-dropdown .ant-select-dropdown {
  background-color: #eb4960;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
}
.env-dropdown .ant-select-dropdown .create-new-env {
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
}
.env-dropdown .ant-select-selection-placeholder {
  color: #f5222d;
}
.env-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.env-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #000000;
}
.env-dropdown .env-select:hover {
  border-color: #f5222d !important;
}
.env-dropdown .ant-select-item {
  font-size: 12px;
  color: #15161b;
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 6px;
}
.env-dropdown .ant-select-arrow {
  color: #fff;
}
.env-dropdown .ant-select-item-option-content {
  color: #fff;
}
.env-dropdown .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff;
}
/*Login Styles*/
.gx-login-container {
  position: relative;
  height: 100%;
  background-color: #9ab999;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.gx-login-content {
  max-width: 450px;
  width: 94%;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: #263238;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  font-size: 14px;
}
.gx-login-content .ant-input {
  background-color: #f5f5f5;
  height: auto;
  padding: 6px 12px;
}
.gx-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #eb4960;
}
.gx-login-content .ant-input-affix-wrapper {
  background-color: #f5f5f5;
  max-height: none;
}
.gx-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-login-content {
    padding: 20px 20px 10px;
  }
}
.gx-login-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-login-header {
    margin-bottom: 15px;
  }
}
.gx-login-header-title {
  font-size: 16px;
}
.gx-login-header-subtitle {
  font-size: 12px;
}
.gx-app-login-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: hidden;
}
@media screen and (max-width: 575px) {
  .gx-app-login-wrap {
    padding-top: 20px;
    justify-content: flex-start;
  }
}
.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .gx-app-login-container {
    padding-bottom: 20px;
  }
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-main-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
}
.gx-app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
}
.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #eb4960;
}
.gx-app-login-content .gx-btn {
  padding: 6px 35px !important;
  height: auto;
}
.gx-app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-app-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-app-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-logo-content {
  color: #fff;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gx-app-logo-content > * {
  position: relative;
  z-index: 2;
}
.gx-app-logo-content h1 {
  color: #fff;
}
@media screen and (max-width: 575px) {
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gx-app-logo-content-bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: rgba(235, 73, 96, 0.7);
}
.gx-app-logo-content-bg img {
  width: 100%;
  height: 100%;
}
.gx-app-logo-wid {
  margin-bottom: auto;
}
.gx-app-social-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.gx-app-social-block .gx-social-link,
.gx-app-social-block .gx-social-link li {
  margin: 0;
}
.gx-app-social-block .gx-social-link span {
  border: solid 1px #eb4960;
  border-radius: 50%;
  color: #eb4960;
}
.gx-app-social-block .gx-social-link span:hover,
.gx-app-social-block .gx-social-link span:focus {
  color: #fff;
  background-color: #eb4960;
}
.gx-app-social-block p {
  margin-bottom: 0;
}
.gx-signin-form .ant-form-item-control-input {
  min-height: 40px;
}
.site-title {
  font-size: 36px;
  color: #f4837d;
}
.add-item {
  margin: 30px;
}
.project-search {
  display: flex;
  justify-content: space-between;
}
.add-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 170.23%;
  color: #fff;
}
.input-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}
.input-box {
  max-width: 100%;
}
.back-button {
  background: #eb4960;
  border: none;
  border-radius: 10px;
  width: 11%;
}
.project-wrapper {
  height: calc(100vh - 300px);
  overflow: auto;
  margin-top: 25px;
}
.project-wrapper .project-container {
  width: 98%;
  height: auto;
  border: 1px solid #9ab999;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 30px 10px 0px 30px;
}
.project-wrapper .project-header {
  display: flex;
  flex-flow: row;
}
.project-wrapper .left-header {
  flex: 1;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.project-wrapper .right-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.project-wrapper .project-name {
  color: #feceaa;
}
.project-wrapper .add-member {
  color: #83adfe;
}
.project-wrapper .delete-project {
  color: #f9536d;
}
.project-wrapper .view-project {
  color: #68ffba;
}
.project-wrapper .project-body {
  display: flex;
  flex-flow: column;
  padding-top: 20px;
}
.project-wrapper .body-item {
  display: flex;
  flex-flow: row;
  margin: 3px 0;
}
.project-wrapper .item-title {
  color: #f4837d;
}
.project-wrapper .user-bar {
  width: 200px;
  height: 25px;
  margin-left: 10px;
  background: #9ab999;
  border: 1px solid #feceaa;
  box-sizing: border-box;
  border-radius: 100px;
}
.project-wrapper .right-platform {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
.project-wrapper .date-tab {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 10px;
  width: 120px;
  height: 40px;
  margin-left: 50px;
  background: #eb4960;
  border-radius: 10px;
  font-size: 10px;
}
.project-wrapper .left-services {
  width: 70%;
}
.project-wrapper .right-services {
  justify-content: flex-end;
  font-style: italic;
  color: #feceaa;
}
.no-project {
  width: 100%;
  max-width: 584px;
  height: calc(100% - 169px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.no-project .img-section {
  height: 160px;
  width: 200px;
  margin: 0px 30px;
}
.no-project .text-section {
  font-size: 24px;
  font-weight: 400;
}
div.project-wrapper > div:not(:first-child) {
  margin-top: 3%;
}
.analytic-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  color: #000000;
}
.global-container {
  margin-left: 40px;
  margin-top: 40px;
}
.analytic-dropdown {
  position: relative;
  height: 42px;
  border-radius: 50px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  color: #545454;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.val {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #eb4960;
}
.global-chart {
  margin: 5%;
}
.copy-text {
  width: 64px;
  height: 41px;
  left: 1359px;
  top: 354px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 170.23%;
  color: #feceaa;
}
.add-item {
  margin: 30px;
}
.step-content-wrapper {
  height: calc(100vh - 317px);
  overflow-y: scroll;
}
.add-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 170.23%;
  color: #fff;
}
.input-title {
  font-weight: 200;
  font-size: 15px;
  color: #fff;
}
.input-box {
  max-width: 60%;
  width: 100%;
}
.push-select {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  width: 250px;
}
.push-select .ant-select-selection-item {
  color: #feceaa;
}
.status-bar {
  width: 100%;
  max-width: 80%;
  margin: auto;
  height: 80px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.status-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #fff;
}
.status-line {
  margin-top: -20px;
  position: static;
  width: 246px;
  height: 0px;
  border: 4px solid #feceaa;
}
.status-active {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #92e3a9;
}
.delivery-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-size: 13px;
  color: #fff;
}
.form-container {
  width: 50%;
  margin: 50px;
}
.form-container .submit-btn {
  margin-top: 80px;
}
.sb-otp-input input {
  margin-right: 10px;
  width: 2.5em !important;
  text-align: center;
  border-radius: 10px;
  height: 2.5em;
}
.sb-login-btn {
  background-color: #eb4960;
  color: #fff;
  font-size: 14px;
}
.sb-send-again {
  color: #eb4960;
  padding: 0%;
  cursor: pointer;
}
.sb-send-again:hover {
  color: #eb4960;
}
.sb-send-again:disabled {
  color: #262626;
}
.gx-login-header .gx-login-header-title {
  font-size: 20px;
}
.gx-login-header .gx-login-header-subtitle {
  font-size: 14px;
}
