@import './variables.less';
#root {
  height: 100vh;
}

::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: @secondary-red-color;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid @secondary-red-color;
  /* creates padding around scroll thumb */
}
.ant-btn-icon {
  font-size: 17px;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: @h2-font-size;
    cursor: pointer;
  }
}

.gx-sidebar-content {
  & > .ant-menu {
    & > .ant-menu-item {
      margin: 12px 0;

      & > .menu-icons {
        font-size: @h2-font-size;
      }

      & > span {
        font-size: @h3-font-size;
      }
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: @h2-font-size;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input {
  border-radius: 10px;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-modal-body {
  padding: 24px !important;
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid @normal-color;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid @normal-color;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid @normal-color;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid @border-color-card;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: @card-bg-color;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

//CUSTOM STYLE CLASSES START

//margin & padding style
.m-30 {
  margin: 30px;
}

.m-0 {
  margin: 0;
}

.mt-10 {
  margin-top: 10px;
}
.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-30 {
  margin-left: 30px;
}

.p-0 {
  padding: 0;
}

//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.gap-16 {
  gap: 16px;
}

// flex-box related style

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-48 {
  width: 48%;
}

.line-24 {
  line-height: 24px;
}

.fill-width {
  width: 100%;
}

//width classes

//cursor
.pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

//cursor

//border style
.b-0 {
  border: none;
}

//border style

.font-xx-large {
  font-size: xx-large;
}

.font-large {
  font-size: large;
}

.font-18 {
  font-size: 18px;
}

.gx-layout-content {
  background-color: @background-color;
}

.gx-main-content-wrapper .title {
  font-weight: 400;
  font-size: 30px;
}

//! Font Family
.font-poppins {
  font-family: 'Poppins', sans-serif;
}
.ant-btn .ant-btn-icon {
  margin-right: 8px;
}
.ant-btn {
  line-height: 1.3;
}

.search-input {
  width: 100%;
  caret-color: @white-color;
  color: @primary-green-color;
  border: 1px solid @primary-green-color !important;
  border-radius: 20px;

  .ant-input::placeholder {
    color: @primary-green-color;
  }
  .ant-input {
    color: @primary-green-color !important;
  }

  &.ant-input-affix-wrapper > input.ant-input {
    padding-left: 2px;
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid @primary-green-color;
    box-shadow: 4px 4px 30px rgb(137 163 134 / 25%);
    border-radius: 20px;
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
    border: 1px solid @primary-green-color;
    box-shadow: 4px 4px 30px rgb(137 163 134 / 25%);
    border-radius: 20px;
  }
}

.chart {
  width: 95%;
  height: 440px;
}

.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: @white-color;
  font-size: @h5-font-size;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}

.full-height-card {
  height: calc(100% - 82px);
  border: none;
  background-color: @background-color;

  .sub-header {
    .title {
      color: @black-color;
      font-size: @font-size-36;
    }

    .message {
      color: @white-color;
      font-size: px;
    }

    .add-btn {
      margin-top: 10px;
    }
  }
}

.add-container {
  padding: 50px 50px 50px 50px;
  background: @table-cell-color;
  border-radius: 25px;
}

.ant-tabs-nav-more svg {
  width: 24px;
}

.tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @white-color;
}

.tabs .ant-tabs-nav-list .ant-tabs-tab {
  padding: 12px 0 0;
  transition: none;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs.ant-tabs-top.tabs .ant-tabs-nav {
  margin: 0px;
}

.ant-tabs.ant-tabs-top.tabs .ant-tabs-nav::before {
  border-bottom: none;
  content: none;
}

.ant-tabs-content {
  height: 100%;
}

.tab-content-block {
  min-height: 250px;
  height: calc(100vh - 200px);
  overflow: auto;
  background: @table-cell-color;
  // background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 33px;
  color: @white-color;
  transition: none;
}

.ant-tabs-content-holder {
  .ant-tabs-content {
    .ant-tabs-tabpane {
      &:first-child {
        .tab-content-block {
          border-radius: 0 30px 30px 30px;
        }
      }

      .tab-content-block {
        border-radius: 30px;
      }
    }
  }
}

.no-border-top-left-radius {
  .tab-content-block {
    border-radius: 0 30px 30px 30px;
  }
}

//kept for future use
.without-sub-header-content {
  .ant-tabs-content-holder {
    height: calc(100vh - 150px);
    background: @table-cell-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    padding: 33px;
    color: @white-color;
  }
}

.ant-tabs {
  .ant-tabs-tab {
    font-size: @font-size-24;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background: unset;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border: none;
  background: unset;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 32px;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  float: left;
  padding: 10px 35px;
  text-decoration: none;
  font-size: 22px;
  color: @black-color;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}

.ant-tabs-tab.ant-tabs-tab-active {
  z-index: 3;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background: @table-cell-color;
  color: @white-color;
}

.ant-tabs-tab.ant-tabs-tab ::before,
.ant-tabs-tab.ant-tabs-tab ::after,
.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::before,
.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::after {
  /* All pseudo elements are
     abs. positioned and on bottom */
  position: absolute;
  bottom: 0;
}

.ant-tabs-tab.ant-tabs-tab:last-child::after,
.ant-tabs-tab.ant-tabs-tab:last-child
  .ant-tabs-tab.ant-tabs-tab
  .ant-tabs-tab-btn::after,
.ant-tabs-tab.ant-tabs-tab:first-child::before,
.ant-tabs-tab.ant-tabs-tab:first-child
  .ant-tabs-tab.ant-tabs-tab
  .ant-tabs-tab-btn::before,
.ant-tabs-tab.ant-tabs-tab-active::after,
.ant-tabs-tab.ant-tabs-tab-active::before,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::after,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::before {
  content: '';
}

.ant-tabs-tab.ant-tabs-tab-active::before,
.ant-tabs-tab.ant-tabs-tab-active::after {
  background: @white-color;

  /* Squares below circles */
  z-index: 1;
}

.ant-tabs-tab.ant-tabs-tab::before,
.ant-tabs-tab.ant-tabs-tab::after {
  background: @table-cell-color;
  width: 20px;
  height: 20px;
  bottom: 0px;
  position: absolute;
}

.ant-tabs-tab.ant-tabs-tab::before {
  left: -20px;
}

.ant-tabs-tab.ant-tabs-tab::after {
  right: -20px;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::after,
.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn::before {
  width: 50px;
  height: 50px;
  border-radius: 20px;
  background: @tab-bg-color;
  z-index: 2;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::after,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::before {
  background-color: @background-color;
}

/* First and last tabs have different
   outside color needs */
.ant-tabs-tab.ant-tabs-tab-active:first-child
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn::before,
.ant-tabs-tab.ant-tabs-tab-active:last-child
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn::after {
  background: @tab-bg-color;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::before {
  left: -50px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::after {
  right: -50px;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  transition: none;
}

.no-border-top-left-radius {
  .ant-tabs-content-holder {
    border-top-left-radius: 0px;
  }
}

.no-border-top-right-radius {
  .ant-tabs-content-holder {
    border-top-right-radius: 0px;
  }
}

.card-body-p-24 {
  .ant-card-body:first-child {
    padding: 12px 24px;
  }
}

.primary-button {
  background-color: @primary-red-color;
  color: @white-color;
  border-color: @primary-red-color;
  display: inline-block;

  font-weight: 400;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 35px;

  &.ant-btn-primary:hover {
    background-color: @primary-red-color;
    color: @white-color;
    border-color: @primary-red-color;
  }

  &.ant-btn-primary:focus {
    background-color: @primary-red-color;
    color: @white-color;
    border-color: @primary-red-color;
  }

  .ant-btn:not([disabled]):active {
    background-color: @primary-red-color;
    color: @white-color;
    border-color: @primary-red-color;
  }

  .ant-btn-primary:active {
    background-color: @primary-red-color;
    color: @white-color;
    border-color: @primary-red-color;
  }

  &.ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    background-color: @add-button-color;
    color: @white-color;
    border-color: @add-button-color;
  }
}

.secondary-button {
  background-color: @secondary-green-color;
  color: @white-color;
  border-color: @secondary-green-color;
  display: inline-block;

  font-weight: 400;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 35px;

  &.ant-btn-primary:hover {
    background-color: @secondary-green-color;
    color: @white-color;
    border-color: @secondary-green-color;
  }

  &.ant-btn-primary:focus {
    background-color: @secondary-green-color;
    color: @white-color;
    border-color: @secondary-green-color;
  }

  .ant-btn:not([disabled]):active {
    background-color: @secondary-green-color;
    color: @white-color;
    border-color: @secondary-green-color;
  }

  .ant-btn-primary:active {
    background-color: @secondary-green-color;
    color: @white-color;
    border-color: @secondary-green-color;
  }

  &.ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    background-color: @add-button-color;
    color: @white-color;
    border-color: @add-button-color;
  }
}

.sub-header-button {
  background-color: @add-button-color;
  color: @white-color;
  border-color: @add-button-color;
  display: inline-block;

  font-weight: 400;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 35px;

  &.ant-btn-primary:hover {
    background-color: @add-button-color;
    color: @white-color;
    border-color: @add-button-color;
  }

  &.ant-btn-primary:focus {
    background-color: @add-button-color;
    color: @white-color;
    border-color: @add-button-color;
  }

  .ant-btn:not([disabled]):active {
    background-color: @add-button-color;
    color: @white-color;
    border-color: @add-button-color;
  }

  .ant-btn-primary:active {
    background-color: @add-button-color;
    color: @white-color;
    border-color: @add-button-color;
  }
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.white-color {
  color: @white-color;
}

.fw-600 {
  font-weight: 600;
}
.secondary-btn-box-shadow {
  box-shadow: 5px 5px 20px rgba(235, 73, 96, 0.6);
  color: @black-color;

  &.ant-btn-primary:hover {
    background-color: @secondary-green-color;
    color: @black-color;
    border-color: @secondary-green-color;
  }

  &.ant-btn-primary:focus {
    background-color: @secondary-green-color;
    color: @black-color;
    border-color: @secondary-green-color;
  }
}

.ant-form-item {
  margin-top: 10px;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  border-color: unset;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  background-color: @error-background;
  border: 1px solid @error-border;
  outline: none;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: @error-border;
  //visibility: hidden;
}

.ant-form-item-label > label {
  color: @white-color;
}

.tabs-no-data {
  margin-top: 5%;
}

.ant-form-item .ant-select {
  width: unset;
}

.steps-content {
  display: flex;
  justify-content: center;
}

.common-steps {
  .ant-steps-item-icon {
    display: none;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: unset;
  }

  .ant-steps-item-active {
    color: @step-active-color;
  }

  .ant-steps-item-title {
    font-weight: 600;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: @white-color !important;
    margin-left: 10px;
  }

  .ant-steps-item-finish {
    .ant-steps-item-container {
      .ant-steps-item-content {
        .ant-steps-item-description {
          color: @white-color;
          font-weight: 500;
        }
      }
    }
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: @white-color;
    font-weight: 500;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: @white-color;
    font-weight: 500;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: @send-color;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: @send-color;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: @send-color;
  }

  .ant-steps-item-title::after {
    top: 11px;
    height: 3px;
    background: @send-color;
  }
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.hide {
  display: none;
}

.loading_spinner {
  margin-left: 50%;
  margin-top: 10%;
}

.scroll_spinner {
  margin-left: 50%;
}

.ant-btn > .ant-btn-loading-icon .anticon svg {
  display: inline-block;
  color: @white-color;
}

.max-width-column {
  max-width: 400px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: unset;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: unset;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: @text-color;
}

.ant-table-tbody > tr .ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: unset;
}

.ant-popover-inner {
  max-width: 270px;
  background-color: @header-text-color !important;
  border-radius: 15px;
}

.ant-popover .ant-popover-arrow:before {
  background: @header-text-color;
}
.ant-popover-inner-content {
  background: @header-text-color;
  border-radius: 15px;
}

.ant-popconfirm-title {
  color: white !important;
}
.ant-popover-message-title {
  max-width: 250px;
  color: @white-color !important;
  overflow-wrap: break-word;
}

.ant-popover-arrow-content::before {
  background: linear-gradient(
      to left,
      @header-text-color 50%,
      @header-text-color 50%
    )
    no-repeat -10px -10px;
}

.ant-popover-arrow-content {
  background-color: @header-text-color;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 20px;
}

// for new page

.no-data {
  svg {
    width: 250px;
    height: auto;
    margin-bottom: 20px;
  }

  .title {
    font-size: 30px;
  }
}

.add-title {
  margin-bottom: 0;
}

.ant-picker {
  padding: 6px 11px;
  color: @text-color;
}

.ant-modal-close {
  top: 26px;
  right: 26px;
}
.ant-form-item-required::after {
  visibility: visible;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: @white-color;
}
.ant-radio-wrapper .ant-radio-inner::after {
  background-color: @primary-color;
}

#editor-1 {
  iframe {
    min-width: 100% !important;
  }
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: @white-color;
}

.ant-pagination-item {
  overflow: hidden;
}

.ant-pagination-item-active a {
  color: @white-color;
  background: @primary-color;
}

.anticon-edit svg {
  fill: @secondary-green-color;
}
.ant-layout-sider:not(.ant-layout-sider-collapsed) {
  @media (min-width: 1370px) {
    min-width: 280px !important;
  }
}
.sb-stepper.ant-steps {
  .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: @white-color;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: @secondary-green-color;
    border-color: @secondary-green-color;
    .anticon svg {
      color: @white-color;
    }
  }
}

.ant-input[disabled],
.ant-input-disabled {
  background-color: @white-color !important;
}
.ant-pagination-disabled .ant-pagination-item-link {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border: 0;
  cursor: disable;
}
.ant-select-selection-overflow {
  overflow: hidden !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background: @add-button-color;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  color: @white-color;
  border: 0;
}

.logout-avitar {
  align-items: center;
  justify-content: center;
  background: @table-cell-color;
  color: @primary-color;
  .anticon svg {
    margin-top: 16px;
  }
}

.ant-table .ant-table-tbody > tr > td:first-child {
  padding-left: 15px;
}

.add-projects {
  .add-container {
    height: 100%;
  }
}
