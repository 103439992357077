@import '../../styles/variables.less';

.add-item {
  margin: 30px;
}

.project-search {
  display: flex;
  justify-content: space-between;
}

.add-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: @font-size-30;
  line-height: 170.23%;
  color: @white-color;
}

.input-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: @h2-font-size;
  color: @white-color;
}

.input-box {
  max-width: 100%;
}

.back-button {
  background: @primary-red-color;
  border: none;
  border-radius: 10px;
  width: 11%;
}

.project-wrapper {
  height: calc(100vh - 300px);
  overflow: auto;
  margin-top: 25px;

  .project-container {
    width: 98%;
    height: auto;
    border: 1px solid @secondary-green-color;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 30px 10px 0px 30px;
  }

  .project-header {
    display: flex;
    flex-flow: row;
  }

  .left-header {
    flex: 1;
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .right-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .project-name {
    color: @send-color;
  }
  .add-member {
    color: @add-member-text;
  }
  .delete-project {
    color: @delete-project-text;
  }
  .view-project {
    color: @view-project-text;
  }

  .project-body {
    display: flex;
    flex-flow: column;
    padding-top: 20px;
  }

  .body-item {
    display: flex;
    flex-flow: row;
    margin: 3px 0;
  }

  .item-title {
    color: @logo-color;
  }

  .user-bar {
    width: 200px;
    height: 25px;
    margin-left: 10px;
    background: @secondary-green-color;
    border: 1px solid @send-color;
    box-sizing: border-box;
    border-radius: 100px;
  }

  .right-platform {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .date-tab {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 10px;
    width: 120px;
    height: 40px;
    margin-left: 50px;
    background: @primary-red-color;
    border-radius: 10px;
    font-size: @font-size-10;
  }

  .left-services {
    width: 70%;
  }
  .right-services {
    justify-content: flex-end;
    font-style: italic;
    color: @send-color;
  }
}

.no-project {
  width: 100%;
  max-width: 584px;
  height: calc(100% - 169px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .img-section {
    height: 160px;
    width: 200px;
    margin: 0px 30px;
  }

  .text-section {
    font-size: 24px;
    font-weight: 400;
  }
}

div.project-wrapper > div:not(:first-child) {
  margin-top: 3%;
}
