@import '../../styles/variables.less';

.add-item {
  margin: 30px;
}

.step-content-wrapper {
  height: calc(100vh - 317px);
  overflow-y: scroll;
}

.add-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: @h2-font-size;
  line-height: 170.23%;
  color: @white-color;
}

.input-title {
  font-weight: 200;
  font-size: @font-size-15;
  color: @white-color;
}

.input-box {
  max-width: 60%;
  width: 100%;
}

.push-select {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: @font-size-15;
  width: 250px;
  .ant-select-selection-item {
    color: @send-color;
  }
}

.status-bar {
  width: 100%;
  max-width: 80%;
  margin: auto;
  height: 80px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.status-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: @h1-font-size;
  color: @white-color;
}

.status-line {
  margin-top: -20px;
  position: static;
  width: 246px;
  height: 0px;
  border: 4px solid @send-color;
}

.status-active {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: @h6-font-size;
  color: @step-active-color;
}

.delivery-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-size: @font-size-13;
  color: @white-color;
}

.form-container {
  width: 50%;
  margin: 50px;

  .submit-btn {
    margin-top: 80px;
  }
}
